import React from "react"
import "../styles/story.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Link} from 'gatsby'
import { motion } from "framer-motion"
import { containerFast, fadeDown, container, fadeUp, scaleUp } from "../animations"

const IndexPage = () => (
  <Layout className="our-story">
    <SEO title="Our Story" description="Read more about us and what we beleive in!" />
    <motion.div variants={containerFast} initial="out" animate="in">
      <motion.div className="story-img" variants={fadeDown} style={{ backgroundImage: "url(/storefront.centre.jpg)", backgroundPosition: 'top', backgroundSize: 'cover'}} >
        <div className="overlay"/>
        <motion.h1 variants={fadeDown}>Inspiration begins at home!</motion.h1>
      </motion.div>
      <motion.p variants={fadeDown}>
        Birch & Benjamin, located in downtown Thornbury, Ontario, is an
        independent and locally owned and operated store that strives to deliver
        a level of service and specialized attention that you simply won't find
        online or in large chain stores.
        <br />
        <br />
        Our knowledge, experience and helpful advice will ensure the success of
        every project you tackle.
      </motion.p>
      <motion.h2 variants={fadeDown}>Let us inspire you!</motion.h2>
    </motion.div>
    <motion.nav
      variants={container}
      initial="out"
      animate="in"
      className="nav-row"
    >
      <motion.div variants={fadeUp} whileHover={scaleUp}>
        <Link to={"/"}>Go Back</Link>
      </motion.div>
    </motion.nav>
  </Layout>
)

export default IndexPage
